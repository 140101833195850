import React from 'react';

export default props => {
  const { size = 300, ...rest } = props;
  const width = size;
  const height = (size * 274) / 300;

  return (
    <svg width={width} height={height} viewBox="0 0 300 274" preserveAspectRatio="xMidYMid meet" {...rest}>
      <rect y="0.727" style={{ fill: '#C41F3E' }} width="300" height="273.273" />}
      <path
        style={{ fill: '#FFD401' }}
        d="M14.008,191.949c0,12.43,0,19.522,0,29.62c47.355-26.756,137.197-66.76,272.951-77.882
	c0-2.447,0-3.942,0-6.395C187.179,142.759,92.926,161.391,14.008,191.949"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M14.008,232.463c0,10.321,0,15.559,0,20.334c67.575-50.125,166.536-84.621,272.951-96.021
	c0-2.377,0-3.787,0-6.169C181.808,160.117,89.391,188.517,14.008,232.463"
      />
      <path
        style={{ fill: '#FFD401' }}
        d="M165.006,69.676c-2.836,0-4.586-0.215-5.458-0.391c0-0.795,0-19.811,0-19.811
	c0-3.654,0.136-7.064,0.479-9.408c1.093-0.355,4.124-0.846,6.379-0.846c10.818,0,15.58,4.574,15.58,14.547
	C181.986,67.619,171.524,69.583,165.006,69.676 M184.644,96.044c0,13.614-7.594,19.174-19.035,19.174
	c-2.328,0-4.636-0.271-6.061-0.691c0-0.729,0-37.387,0-38.307c0.717,0,5.038,0,5.038,0C179.018,76.219,184.644,83.55,184.644,96.044
	 M180.061,71.742c10.286-2.883,18.938-10.229,18.938-21.378c0-10.694-6.957-17.884-21.199-17.884
	c-6.803,0-15.342,0.693-21.504,0.693c-6.053,0-12.493-0.068-19.87-0.457l-0.467-0.063v4.968l0.434,0.034
	c6.174,0.466,6.629,1.656,6.629,11.636v59.009c0,5.28-0.688,7.855-5.518,8.238l-1.423,0.114v4.884
	c4.686-0.287,13.361-0.499,17.134-0.499c3.788,0,6.688,0.211,11.449,0.404c28.46,1.258,37.724-14.917,37.724-27.916
	c0-15.534-11.417-19.44-22.313-21.658L180.061,71.742z"
      />
      <path
        style={{ fill: '#FFD401' }}
        d="M91.062,109.546c-5.642,3.506-12.214,4.98-19.643,4.98c-20.087,0-30.071-15.473-30.071-40.01
	c0-21.614,9.568-35.896,27.895-35.896c6.246,0,11.296,2.218,14.631,5.371c0.552,2.017,1.291,12.555,1.291,12.555l5.123,0.017v-0.383
	c0.047-4.564,1.32-15.176,2.281-20.515l0.039-0.41l-0.377-0.021C85.036,33.32,77.703,31.7,67.942,31.7
	c-22.103,0-44.656,13.372-44.656,44.334c0,32.469,19.804,46.552,43.688,46.552c6.545,0,15.23-0.998,22.243-4.84l0.11-0.047
	l3.399-6.459l-1.381-1.868L91.062,109.546"
      />
      <path
        style={{ fill: '#FFD401' }}
        d="M275.814,109.546c-5.629,3.506-12.193,4.98-19.647,4.98c-20.075,0-30.054-15.473-30.054-40.01
	c0-21.614,9.569-35.896,27.891-35.896c6.253,0,11.301,2.218,14.634,5.371c0.539,2.017,1.281,12.555,1.281,12.555l5.146,0.017
	l-0.027-0.383c0.056-4.564,1.319-15.176,2.283-20.515l0.052-0.41l-0.375-0.021c-7.216-1.914-14.547-3.534-24.312-3.534
	c-22.088,0-44.639,13.372-44.639,44.334c0,32.469,19.803,46.552,43.7,46.552c6.523,0,15.23-0.998,22.239-4.84l0.099-0.047
	l3.402-6.459l-1.379-1.868L275.814,109.546"
      />
      <path
        style={{ fill: '#FFD401' }}
        d="M114.179,33.173c-5.166,0-10.978-0.068-15.91-0.481l-0.391-0.039v4.923l0.343,0.018
	c6.461,0.408,7.306,1.466,7.306,12.647v56.031c0,8.562-0.417,9.882-6.995,10.303l-0.354,0.037v4.922l0.394-0.043
	c4.965-0.434,10.225-0.513,15.373-0.513c5.063,0,9.908,0.079,15.265,0.513l0.391,0.043v-4.922l-0.333-0.037
	c-6.564-0.421-6.934-1.742-6.934-10.303V50.241c0-11.365,0.41-12.24,6.909-12.647l0.336-0.018v-4.923l-0.394,0.039
	C123.854,33.105,119.255,33.173,114.179,33.173"
      />
    </svg>
  );
};
