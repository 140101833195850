import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import config from './config';
import Routes from './Routes';
import './i18n';

Sentry.init({ dsn: config.sentryDsn, environment: config.env });

ReactDOM.render(<Routes />, document.getElementById('root'));
