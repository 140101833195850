import React from 'react';

export default props => {
  const { size = 48, ...rest } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 48 48" preserveAspectRatio="xMidYMid meet" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="#F2F3F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5076 15.0087C27.366 15.0087 24.5772 11.3438 24.5508 11.3066C24.4418 11.1576 24.4354 11.0707 24.1875 10.6875C23.9396 11.0707 23.8552 11.1576 23.7462 11.3066C23.7199 11.3438 21.009 15.0087 17.8674 15.0087H14.9062V26.1666C14.9062 28.9658 22.3646 35.8325 23.8396 37.1807C23.9451 37.2765 24.1875 37.5 24.1875 37.5C24.1875 37.5 24.3469 37.2765 24.4454 37.1807C25.9204 35.8325 33.2812 28.9658 33.2812 26.1666V15.0087H30.5076Z"
        fill="#C41F3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5076 15.0084C27.3766 15.0084 24.6551 11.342 24.6288 11.3066C24.5198 11.1576 24.1875 10.6875 24.1875 10.6875V37.5C24.1875 37.5 24.4407 37.2765 24.5391 37.1807C26.0141 35.8326 33.375 28.9663 33.375 26.1673V15.0084H30.5076Z"
        fill="#8B1D41"
      />
      <path
        d="M20.3438 18.1875C20.8931 18.1875 21.6917 17.9867 22.5622 17.3292C23.1738 16.8758 23.721 16.3447 24.1893 15.75C24.645 16.3334 25.1759 16.8567 25.7689 17.3069C26.6061 17.9575 27.4942 18.1875 28.0312 18.1772"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};
