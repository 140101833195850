import React from 'react';

// Pass only width, height will be adjusted automatically
export default function LendingLoopLogo(props) {
  const { style, color = '#492660', innerColor = '#fff', width = 172, ...other } = props;

  const svgStyle = {
    stroke: color,
    width: width,
    height: (204 * width) / 1104,
    display: 'block',
    ...style
  };

  return (
    <svg viewBox="0 0 1104 206" style={svgStyle} {...other}>
      <clipPath id="a">
        <path d="m0 210h884v-210h-884z" />
      </clipPath>
      <g clipPath="url(#a)" transform="matrix(1.3333333 0 0 -1.3333333 -37.333332 242.66666)">
        <path
          d="m0 0c0-42.25-34.25-76.5-76.5-76.5s-76.5 34.25-76.5 76.5 34.25 76.5 76.5 76.5 76.5-34.25 76.5-76.5"
          fill={color}
          transform="translate(181 105.5)"
        />
        <path
          d="m0 0 15.46-15.46-39.615-39.618s-3.698-4.006 0-7.701c3.696-3.698 20.01-20.012 20.01-20.012l-15.796-15.796-23.863 23.863.336 31.255z"
          fill={innerColor}
          transform="translate(104.1709 167.1318)"
        />
        <path
          d="m0 0s-4.083-3.815 0-7.897 11.511-11.511 11.511-11.511l15.747 15.817-15.411 15.438"
          fill={innerColor}
          stroke="none"
          transform="translate(49.6 111.8164)"
        />
        <path
          d="m0 0-15.46 15.461 39.615 39.616s3.698 4.006 0 7.702c-3.696 3.698-20.01 20.012-20.01 20.012l15.796 15.795 23.863-23.861-.336-31.257z"
          fill={innerColor}
          transform="translate(105.7949 46.8682)"
        />
        <path
          d="m0 0s4.082 3.813 0 7.896c-4.083 4.083-11.511 11.512-11.511 11.512l-15.747-15.818 15.411-15.439"
          fill={innerColor}
          stroke="none"
          transform="translate(160.4 102.1846)"
        />
        <g fill={color}>
          <path d="m0 0h20.138v-13.577h-38.089v64.515h17.951z" transform="translate(219.8726 85.874)" />
          <path
            d="m0 0v-10.661h20.867v-13.578h-20.867v-11.663h23.601v-14.307h-41.552v64.516h41.552v-14.307z"
            transform="translate(264.7046 122.5059)"
          />
          <path
            d="m0 0h-17.952l-23.692 35.994v-35.994h-17.951v64.424h17.951l23.692-36.449v36.449h17.952z"
            transform="translate(356.1006 72.2969)"
          />
          <path
            d="m0 0c3.037 2.855 4.556 6.895 4.556 12.12 0 5.162-1.519 9.188-4.556 12.073-3.039 2.885-7.199 4.329-12.484 4.329h-6.105v-32.804h6.105c5.285 0 9.445 1.427 12.484 4.282m18.589-4.602c-2.734-4.89-6.622-8.733-11.664-11.527-5.043-2.795-10.966-4.191-17.769-4.191h-25.697v64.515h25.697c6.803 0 12.742-1.352 17.815-4.055 5.071-2.703 8.96-6.485 11.664-11.345 2.702-4.86 4.054-10.419 4.054-16.675 0-6.258-1.366-11.832-4.1-16.722"
            transform="translate(402.5723 92.6172)"
          />
          <path d="m451.231 72.297h-17.951v64.516h17.951z" />
          <path
            d="m0 0h-17.951l-23.693 35.994v-35.994h-17.951v64.424h17.951l23.693-36.449v36.449h17.951z"
            transform="translate(520.7588 72.2969)"
          />
          <path
            d="m0 0c5.285-4.01 8.475-9.417 9.568-16.22h-18.863c-.972 1.761-2.4 3.158-4.282 4.192-1.884 1.032-4.101 1.549-6.652 1.549-4.375 0-7.853-1.474-10.434-4.42-2.583-2.947-3.873-6.88-3.873-11.8 0-5.591 1.382-9.828 4.146-12.713s6.819-4.328 12.165-4.328c3.037 0 5.709.73 8.019 2.188 2.309 1.458 4.131 3.613 5.468 6.469h-17.861v12.757h32.714v-17.768c-2.309-5.347-6-9.919-11.072-13.715-5.072-3.797-11.467-5.695-19.182-5.695-6.56 0-12.3 1.381-17.221 4.146-4.921 2.764-8.703 6.637-11.345 11.618-2.643 4.981-3.964 10.662-3.964 17.041 0 6.378 1.321 12.042 3.964 16.994 2.642 4.951 6.392 8.808 11.253 11.573 4.86 2.763 10.57 4.146 17.132 4.146 8.26 0 15.035-2.005 20.32-6.014"
            transform="translate(581.5371 131.3452)"
          />
          <path d="m0 0h20.139v-13.577h-38.09v64.515h17.951z" transform="translate(634.4785 85.874)" />
          <path
            d="m0 0c-2.612-2.947-3.918-6.971-3.918-12.074 0-4.982 1.306-8.961 3.918-11.937 2.611-2.977 6.196-4.465 10.753-4.465 4.495 0 8.049 1.488 10.661 4.465 2.611 2.976 3.918 6.955 3.918 11.937 0 5.103-1.291 9.127-3.872 12.074-2.583 2.946-6.151 4.42-10.707 4.42-4.557 0-8.142-1.474-10.753-4.42m27.383 16.767c5.012-2.825 8.96-6.759 11.846-11.801 2.884-5.043 4.329-10.722 4.329-17.04 0-6.319-1.458-11.998-4.374-17.04-2.916-5.043-6.88-8.976-11.892-11.8-5.013-2.825-10.525-4.237-16.539-4.237-6.015 0-11.543 1.412-16.585 4.237-5.043 2.824-9.022 6.757-11.938 11.8-2.915 5.042-4.373 10.721-4.373 17.04 0 6.318 1.458 11.997 4.373 17.04 2.916 5.042 6.895 8.976 11.938 11.801 5.042 2.825 10.57 4.237 16.585 4.237 6.073 0 11.618-1.412 16.63-4.237"
            transform="translate(681.6797 116.8105)"
          />
          <path
            d="m0 0c-2.612-2.947-3.918-6.971-3.918-12.074 0-4.982 1.306-8.961 3.918-11.937 2.611-2.977 6.196-4.465 10.753-4.465 4.495 0 8.049 1.488 10.661 4.465 2.611 2.976 3.918 6.955 3.918 11.937 0 5.103-1.291 9.127-3.872 12.074-2.583 2.946-6.151 4.42-10.707 4.42-4.557 0-8.142-1.474-10.753-4.42m27.383 16.767c5.012-2.825 8.96-6.759 11.846-11.801 2.884-5.043 4.329-10.722 4.329-17.04 0-6.319-1.458-11.998-4.374-17.04-2.916-5.043-6.88-8.976-11.892-11.8-5.013-2.825-10.525-4.237-16.539-4.237-6.015 0-11.543 1.412-16.585 4.237-5.043 2.824-9.022 6.757-11.938 11.8-2.915 5.042-4.373 10.721-4.373 17.04 0 6.318 1.458 11.997 4.373 17.04 2.916 5.042 6.895 8.976 11.938 11.801 5.042 2.825 10.57 4.237 16.585 4.237 6.073 0 11.618-1.412 16.63-4.237"
            transform="translate(753.5752 116.8105)"
          />
          <path
            d="m0 0c0 4.738-2.612 7.107-7.837 7.107h-6.834v-14.306h6.834c5.225 0 7.837 2.399 7.837 7.199m-14.671-21.323v-21.596h-17.951v64.515h26.79c7.775 0 13.714-1.959 17.814-5.877 4.101-3.919 6.152-9.158 6.152-15.719 0-4.071-.911-7.716-2.735-10.935-1.822-3.221-4.541-5.756-8.155-7.609-3.615-1.854-7.973-2.779-13.076-2.779z"
            transform="translate(837.7715 115.2163)"
          />
        </g>
      </g>
    </svg>
  );
}
