import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './history.js';
import SignOut from './components/auth/SignOut';
import SignIn from './components/auth/SignIn';
import Amplify, { Auth } from 'aws-amplify';
import config from './config';
import { useTranslation } from 'react-i18next';

import './styles';

Amplify.configure({
  Auth: {
    region: config.awsRegion,
    userPoolId: config.awsUserPoolId,
    userPoolWebClientId: config.awsUserPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/sign-out" component={SignOut} />
        <RoutePublic exact path="/:lang?" component={SignIn} />
        <Route exact path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

export default Routes;

const RoutePublic = ({ component: Component, ...rest }) => {
  const { lang } = rest.computedMatch.params || {};
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  if (lang && lang !== i18n.language) i18n.changeLanguage(lang);
  const currentLanguage = (i18n.language && i18n.language.substr(0, 2)) || 'en';

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        const token = session.idToken.jwtToken;
        const urlPart = window.location.search.replace('?', '');
        const url = `${config.coreAppUrl}${currentLanguage}/cognito-confirm-sign-in?id_token=${token}&${urlPart}`;
        window.location.href = url;
      })
      .catch(_e => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Route
      render={props => {
        if (isLoading) return null;
        return <Component {...props} />;
      }}
      {...rest}
    />
  );
};
