import React from 'react';
import { Row, Container, Col, Dropdown } from 'react-bootstrap';
import CibcLogo from './svg/cibc';
import { useTranslation } from 'react-i18next';
import history from '../history';

function Header() {
  const { i18n } = useTranslation();
  const isFrench = (i18n.language || '').includes('fr');
  const updateLanguage = lang => {
    history.push(lang);
  };
  return (
    <>
      <div className="bg-primary" style={{ height: 4 }} />
      <div className="bg-white ">
        <Container>
          <Row>
            <Col className="py-3 d-flex align-items-center justify-content-between">
              <a href="https://www.cibc.com/en/personal-banking.html">
                <CibcLogo size="56" />
              </a>

              <Dropdown>
                <Dropdown.Toggle className="bg-white text-charcoal border-0 outline-none shadow-none fs-1 fwn d-flex align-items-center">
                  {isFrench ? 'FR' : 'EN'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={e => updateLanguage('en')}>English CA</Dropdown.Item>
                  <Dropdown.Item onClick={e => updateLanguage('fr')}>French CA</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-mid-grey" style={{ height: 1 }} />
    </>
  );
}

export default Header;
