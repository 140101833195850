// appStage: development | production
const dev = {
  coreAppUrl: 'https://my.tahoe.lendingloop.ca/',
  env: 'development',
  awsRegion: 'ca-central-1',
  awsUserPoolId: 'ca-central-1_lEuIjBFTc',
  awsUserPoolWebClientId: '2oedmelaf4ti7c8h05u4bc17hl',
  sentryDsn: 'https://6c1fa69a33e64a52a953cc6e43bca18b@sentry.io/5183081'
};

const config = {
  coreAppUrl: process.env.REACT_APP_CORE_APP_URL || dev.coreAppUrl,
  env: process.env.REACT_APP_ENV || dev.env,
  awsRegion: process.env.REACT_APP_AWS_REGION || dev.awsRegion,
  awsUserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || dev.awsUserPoolId,
  awsUserPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || dev.awsUserPoolWebClientId,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || dev.sentryDsn
};

export default config;
