import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Footer.module.scss';
import LendingLoopLogo from './svg/LendingLoopLogo';
import { useTranslation } from 'react-i18next';

const links = {
  legal: 'https://www.cibc.com/en/legal.html',
  privacy: 'https://www.cibc.com/en/privacy-security.html'
};

function Footer() {
  const { t } = useTranslation();
  return (
    <div className={styles.root + ' bg-charcoal'}>
      <Container>
        <Row>
          <Col className="d-flex col pt-4 fs-08 fw-600 justify-content-between">
            <p className="text-white mb-0 fw-600">
              {t('Canadian Imperial Bank of Commerce Website - Copyright © CIBC.')}
            </p>
            <div className="d-flex">
              <a className="text-white ml-4" href={links.privacy}>
                {t('Privacy and Security')}
              </a>
              <a className="text-white ml-4" href={links.legal}>
                {t('Legal')}
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="https://www.lendingloop.ca" className={styles.lendingLoopLink}>
              {t('POWERED BY')} &nbsp;
              <LendingLoopLogo width="132" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
