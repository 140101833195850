import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const translation = {
  en: {
    translation: {
      Help: 'Help',
      AdChoices: 'AdChoices',
      Legal: 'Legal',
      Password: 'Password',
      'Canadian Imperial Bank of Commerce Website - Copyright © CIBC.':
        'Canadian Imperial Bank of Commerce Website - Copyright © CIBC.',
      'CDIC Deposit Insurance Information': 'CDIC Deposit Insurance Information',
      'Privacy and Security': 'Privacy and Security',
      'POWERED BY': 'POWERED BY',
      'Email is mandatory': 'Email is mandatory',
      'Password is mandatory': 'Password is mandatory',
      'Incorrect email or password': 'Incorrect email or password',
      'Log In': 'Log In',
      'Business email address': 'Business email address',
      'john.doe@example.com': 'john.doe@example.com',
      'Logging In...': 'Logging In...',
      'Safe banking online, guaranteed': 'Safe banking online, guaranteed',
      'Forgot password?': 'Forgot password?'
    }
  },
  fr: {
    translation: {
      Help: 'Aide',
      'Canadian Imperial Bank of Commerce Website - Copyright © CIBC.':
        'Site Web de la Banque Canadienne Impériale de Commerce © Banque CIBC.',
      'CDIC Deposit Insurance Information': "Renseignements sur l'assurance-dépôts de la SADC",
      AdChoices: 'Choix de pub',
      'Privacy and Security': 'Confidentialité et sécurité',
      Legal: 'Mentions juridiques',
      'POWERED BY': 'Alimenté par',
      'Safe banking online, guaranteed': 'Services bancaires en ligne sécuritaires, garantis',
      'Email is mandatory': 'L’adresse courriel est obligatoire',
      'Password is mandatory': 'Le mot de passe est obligatoire',
      'Incorrect email or password':
        'Le mot de passe que vous avez entré est incorrect. Réessayez ou connectez-vous autrement',
      'Log In': 'Connexion',
      'Business email address': 'Adresse de courriel',
      'john.doe@example.com': 'john.dupont@example.com',
      Password: 'Mot de passe',
      'Logging In...': 'Se connecter...',
      'Forgot password?': 'Vous avez oublié votre mot de passe?'
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translation,
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });
