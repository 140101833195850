import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Auth from '@aws-amplify/auth';
import config from '../../config';
import styles from './SignIn.module.css';
import * as Sentry from '@sentry/browser';
import Header from '../Header';
import Footer from '../Footer';
import RedShield from '../svg/RedShield';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function CustomSignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [serverError, setServerError] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState();
  const { t, i18n } = useTranslation();
  const currentLanguage = (i18n.language && i18n.language.substr(0, 2)) || 'en';

  const handleSubmit = async () => {
    setIsLoggingIn(true);
    setServerError(false);

    if (!email || !email.trim()) {
      setIsLoggingIn(false);
      return setServerError(t('Email is mandatory'));
    }

    if (!password || !password.trim()) {
      setIsLoggingIn(false);
      return setServerError(t('Password is mandatory'));
    }

    try {
      localStorage.clear();
      await Auth.signIn(email.toLowerCase().trim(), password);
      window.location.href = '';
    } catch (err) {
      setIsLoggingIn(false);
      if (err && (err.code === 'UserNotFoundException' || err.code === 'InvalidParameterException' || !err.code)) {
        setServerError('Incorrect email or password');
      } else if (err && err.message) {
        const errorMessage = err.message.replace(/username/gi, 'email');
        setServerError(errorMessage);

        if (errorMessage === 'QuotaExceededError') {
          Sentry.captureException(err);
        }
      } else {
        setServerError('Incorrect email or password');
      }
    }
  };

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.currentTarget.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Header />
      <div>
        <Container>
          <Row>
            <Col className="pb-5">
              <h1 className="text-center my-5">{t('Log In')}</h1>
              <div className={styles.box + ' p-5 mb-4'}>
                <div className={styles.formField}>
                  <div className={styles.inputLabel}>{t('Business email address')}</div>
                  <input
                    placeholder={t('john.doe@example.com')}
                    name="email"
                    className={styles.input + ' form-control'}
                    value={email}
                    onChange={handleEmailChange}
                    data-testid="email"
                  />
                </div>
                <div className={styles.formField}>
                  <div className={styles.inputLabel}>{t('Password')}</div>
                  <input
                    placeholder="********"
                    type="password"
                    name="password"
                    className={styles.input + ' form-control'}
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                    data-testid="password"
                  />
                </div>

                {serverError && (
                  <div
                    className="small text-charcoal text-left bg-light-pink px-3 py-2 rounded"
                    data-testid="errorMessage"
                  >
                    <FaExclamationTriangle className="text-secondary mr-2" />
                    {t(serverError)}
                  </div>
                )}

                <div className="d-flex justify-content-between mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                    disabled={isLoggingIn}
                    data-testid="signInBtn"
                  >
                    {isLoggingIn ? t('Logging In...') : t('Log In')}
                  </button>
                </div>

                <div className="mt-4 pt-4 text-center">
                  <RedShield />
                  <p className="text-center mt-3 fs-08-sm mb-0">{t('Safe banking online, guaranteed')}</p>
                </div>
              </div>

              <div className={styles.forgotPasswordContainer + ' mb-5'}>
                <a
                  className="float-right text-charcoal fwb"
                  href={`${config.coreAppUrl}users/password/new?locale=${currentLanguage}`}
                >
                  {t('Forgot password?')}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default CustomSignIn;
